import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import styled from 'styled-components';

const windowGlobal = typeof window !== 'undefined' && window;

const clientUser: string = "demo";
const clientPassword: string = "BMPC4K";

const CustomButton = styled(Button)`
  border-color: #10bdc6;
  background-color: #10bdc6;

  &.btn-primary:active, &.btn-primary:visited {
    background-color: #10bdc6 !important;
  }

  &.btn-primary:hover {
    background-color: #3CA2A4 !important;
    border-color: #3CA2A4;
  }
`

const LoginModal = () => {
  const [errorState, setErrorState] = useState(false)
  const usernameRef = useRef()
  const passwordRef = useRef()

  const handleClose = () => {
      navigate("/")
  }

  const handleLogin = () => {
    if (usernameRef === undefined || passwordRef === undefined) {
      handleClose();
    }

    const username = usernameRef.current.value!;
    const password = passwordRef.current.value;

    if (username && username === clientUser && password && password === clientPassword) {
        setErrorState(false);

        if (windowGlobal.localStorage) {
            windowGlobal.localStorage.setItem("user", "demouser");
        }

        window.location.reload();
    } else {
        setErrorState(true);
    }
  }

  return <>
    <Modal show={true} onHide={handleClose}  backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Authorisierung erforderlich</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Alert show={errorState} variant={'danger'}>Nutzername und/oder Passwort ist falsch.</Alert>
      <Form>
          <Form.Group controlId="formBasicEmail">
              <Form.Label>Nutzername</Form.Label>
              <Form.Control type="username" placeholder="Nutzername eingeben" ref={usernameRef} />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control type="password" placeholder="Passwort" ref={passwordRef} />
          </Form.Group>
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <CustomButton variant="primary" onClick={handleLogin}>
          Einloggen
        </CustomButton>
      </Modal.Footer>
    </Modal>
  </>
}

export default LoginModal;