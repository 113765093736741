import React from "react";
import { Breadcrumb, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'

import LoginModal from '../components/login';
import { VideoElement } from '../components/videoElement';
import SEO from '../components/seo';

const windowGlobal = typeof window !== 'undefined' && window

const Content = {
    data: [
        {
            title: "Einführung der Biotonne im Landkreis Karlsruhe",
            work: "Erklärfilm",
            text: "Konzeption, Kamera, Schnitt, Animation",
            videoId: "IimlBtxJfYE"
        },
        {
            title: "Brillen Steiert",
            work: "Imagefilm",
            text: "Konzeption, Kamera, Schnitt",
            videoId: "AHi3UTEKPEM"
        },
        {
            title: "100 Jahre Jugendeinrichtung Schloss Stutensee",
            work: "Dokumentationsfilm der verschiedenen Einrichtungsbereiche",
            text: "Konzeption, Planung, Kamera, Schnitt, Animation",
            videoId: "vfzjfr7eJm4"
        },
        {
            title: "Leben in der \"individuell geschlossenen Gruppe\"",
            work: "TV-Beitrag, nominiert für den LFK-Medienpreis",
            text: "Idee, Planung, Kamera, Schnitt",
            videoId: "h1kwSvGWxY0"
        },
        {
            title: "Firmenjubiläum bei der E.G.O.",
            work: "Einspielfilm bei der Jubilarfeier",
            text: "Kamera, Schnitt",
            videoId: "OZAe6MhIn9A"
        },
        {
            title: "Eröffnung der Firmenzentrale der E.G.O.",
            work: "Glückwunschfilm als Einspieler bei der Eröffnungsfeier",
            text: "Schnitt, Animation",
            videoId: "XVPCIduTJHY"
        },
        {
            title: "Verband Bildung und Erziehung",
            work: "Einspielfilm zur Eröffnung der Mitgliederversammlung",
            text: "Schnitt, Animation",
            videoId: "tOZXwcWceY4"
        },
        {
            title: "Sondereinrichtungen in der Jugendeinrichtung Schloss Stutensee",
            work: "Imagefilm",
            text: "Konzeption, Kamera, Schnitt",
            videoId: "jy4nef8vSNU"
        },
        {
            title: "Familie Neumann entscheidet sich für das Bringsystem",
            work: "Erklärfilm",
            text: "Konzeption, Animation, Sounddesign",
            videoId: "Q1WwxlbMl4s"
        }
    ]
}

const VideoGallery = () => {
    return <>
    <SEO />
    <Breadcrumb>
        <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHome} color="#10bdc6"/>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Portfolio</Breadcrumb.Item>
    </Breadcrumb>
    <Container>
        <Row>
            { Content.data.map((element, index) => {
                return <VideoElement
                    key={index}
                    title={element.title}
                    work={element.work}
                    text={`Verantwortlich für:\n${element.text}`}
                    videoId={element.videoId}
                />
            })}
        </Row>
    </Container>
    </>
}

const Portfolio = () => {
    let user

    if (windowGlobal.localStorage) {
        user = windowGlobal.localStorage.getItem("user")
    } else {
        user = undefined
    }

    if (!user || user !== "demouser") {
        console.log("User not logged in")
        return <LoginModal />
    } else {
        return <VideoGallery />
    }
}

export default Portfolio;