import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

const CardTitle = styled(Card.Title)`
    color: #10bdc6;
`

const ResponsibleTitle = styled(Card.Title)`
    font-size: 1.1em;
`

const CardText = styled(Card.Text)`
    white-space: pre-line;
`

interface Props {
    title: string;
    work: string;
    text: string;
    videoId: string;
}

export const VideoElement = (props: Props): JSX.Element => {
    return <div className="col-sm-4 py-2">
      <div className="card card-body h-100">
        <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${props.videoId}?showinfo=0&modestbranding=1&rel=0`} allowFullScreen></iframe>
        </div>
        <CardTitle>{props.title}</CardTitle>
        <ResponsibleTitle>{props.work}</ResponsibleTitle>
        <CardText>{props.text}</CardText>
      </div>
    </div>
}